import React from "react"

import {
  LayoutHome,
  Section,
  Container,
  Content,
} from "../components/Components"

import "../styles/style.scss"

import { navigation } from "../common/navigation"
import { Link } from "@reach/router"

const IndexPage = () => (
  <LayoutHome>
    <nav
      className="navbar is-black"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <img src={require("../images/yoox_negativo.png")} />
          </div>
        </div>
        <div className="navbar-menu">
          <div className="navbar-end">
            <a className="navbar-item" href="https://www.kleinrusso.it">
              <img
                src={require("../images/kleinrusso-negativo.png")}
                alt="Kleinrusso"
              />
            </a>
          </div>
        </div>
      </div>
    </nav>
    <Section>
      <Container>
        <Content>
          <ul className="home__menu">
            {navigation.links.map((item, index) => (
              <li className="home__item">
                <Link key={index} className="title link-label" to={item.url}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </Content>
      </Container>
    </Section>
  </LayoutHome>
)

export default IndexPage
